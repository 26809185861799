import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaginationModel } from 'src/app/shared/models/pagination';
import { FaqModel, SearchsFaq } from 'src/app/shared/models/suppliers/faq-model';
import { AdminService } from 'src/app/shared/services/admin.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  faqsList: PaginationModel<FaqModel>;
  filteredFaqs: FaqModel[] = [];
  currentPage: number = 1;
  searchQuery: string = '';
  faq:number;
  question:string='';
  filterActive: boolean = false;


  isFilterDropdownVisible: boolean = false; 
  selectedFilterType: string = ''; 
  constructor(
    private _adminService: AdminService,
    private _notificationService: NotificationsService,
    private _router: Router,
    private _navigationService: NavigationService,
  ) {}

  ngOnInit(): void {
    this.getFaqs(1);
  }

  getFaqs(page: number): void {
    
    this._adminService.getSuppilerFaq(page).subscribe((res) => {
    
      this.faqsList = res.data;
      this.filteredFaqs = this.faqsList.list; 
    });
  }
 


  search(): void {
    this.currentPage = 1;
    if (this.searchQuery.trim() !== "") {
      const searchData: SearchsFaq = {
        faqType:this.faq,
        question: this.searchQuery,
        pageNumber: this.currentPage,
      };
  
      this._adminService.searchFaq(searchData).subscribe(
        (response) => {
          this.faqsList = response.data;
          this.filteredFaqs = this.faqsList.list;
        },
        (error) => {
          console.error("Error searching FAQs", error);
        }
      );
    } else {
  
      this.getFaqs(this.currentPage);
    }
  }
  clearSearch(): void {
    this.searchQuery = '';
    this.currentPage = 1;
    this.getFaqs( this.currentPage); 
  }
  
  
  toggleAnswer(item: FaqModel): void {
    this.filteredFaqs.forEach((faq) => {
      if (faq !== item) {
        faq['isVisible'] = false; 
      }
    });
    item['isVisible'] = !item['isVisible'];
  }

  filterFaqs(): void {
    const query = this.searchQuery.toLowerCase();
    this.filteredFaqs = this.faqsList.list.filter(
      (faq) =>
        faq.question.toLowerCase().includes(query) ||
        faq.answer.toLowerCase().includes(query)
    );
  }



  toggleFilterDropdown(): void {
    this.isFilterDropdownVisible = !this.isFilterDropdownVisible;
  }

 
  onPageChange(page: number): void {
    this.currentPage = page;
    if (this.searchQuery) {
      this.search();
    } else {
      this.getFaqs(page);
    }
  }
  goBack(): void {
    this._navigationService.back();
  }
  

}
